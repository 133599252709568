import React, { useRef, useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Space,
    Spin,
    InputNumber,
    Image,
    Layout,
    Select
} from "antd";
import { ChromeOutlined } from '@ant-design/icons'
import { clusteringColors } from '../util/clusteringColors.js';
import { hexToRgb, rgbToHex } from '../util/utils';
import { saveAs } from 'file-saver';
import './index.css'

const { Option } = Select;

const Pixelizer = () => {
    const imageRef = useRef(null)
    const canvasRef = useRef(null)
    const outputRef = useRef(null)
    const [originalDim, setOriginalDim] = useState({ width: 0, height: 0 })
    const [pixelSize, setPixelSize] = useState(5)
    const [resizeType, setResizeType] = useState(1)
    const [numberOfColors, setNumberOfColors] = useState(40)
    const [result, setResult] = useState(null)
    const [resizedImage, setResizedImage] = useState(null)
    const [exportImageData, setExportImageData] = useState(null)
    const [grayscaleImage, setGrayscaleImage] = useState(null)
    const [pixelizedImage, setPixelizedImage] = useState(null)
    const [isPixelize, setIsPixelize] = useState(false)
    const [base64, setBase64] = useState(null)
    const [mimeType, setMimeType] = useState(null)

    useEffect(() => {
        import("@silvia-odwyer/photon").then(photon => {
            console.log('loaded')
            window.photon = photon
        })
    }, [])

    useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        const img = new window.Image
        img.onload = function () {
            canvas.width = img.width
            canvas.height = img.height
            setOriginalDim({ width: img.width, height: img.height })
            setResizedImage(null)
            setPixelizedImage(null)
            setGrayscaleImage(null)
            // ctx.fillStyle = '#000';
            ctx.drawImage(img, 0, 0); // destination rectangle
        }
        img.src = `data:${mimeType};base64,${base64}`
    }, [base64])

    const _onClickPixelize = () => {
        _onResize()
        const canvas = outputRef.current.children[0]
        if (!canvas) {
            setIsPixelize(false)
            return
        }
        const ctx = canvas.getContext('2d')
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        console.log(imageData.width, imageData.height)
        const listColors = []
        let rows = imageData.height;
        let cols = imageData.width;

        // console.log(imageData.data)
        for (var i = 0; i < imageData.data.length; i += 4) {
            const r = imageData.data[i]
            const g = imageData.data[i + 1]
            const b = imageData.data[i + 2]
            // if (i === 0)
            //     console.log(imageData.data.slice(i, i + 4).join(' '))
            if (imageData.data[i + 3] === 0) {
                continue;
            }
            if (r !== 255 || g !== 255 || b !== 255)
                listColors.push([r, g, b])
        }

        console.log('Pixelize...')
        const result = clusteringColors(numberOfColors, listColors)
        // console.log(result)
        const { clusteredColors, colorPalette } = result
        setResult(result)
        // console.log(clusteredColors)
        setExportImageData(imageData)

        const pixelizedImageData = _mapColor(imageData, clusteredColors, colorPalette)
        const pixelizedCanvas = document.createElement("CANVAS");
        pixelizedCanvas.width = pixelizedImageData.width
        pixelizedCanvas.height = pixelizedImageData.height
        const ctx2 = pixelizedCanvas.getContext("2d")
        ctx2.putImageData(pixelizedImageData, 0, 0);
        setPixelizedImage(pixelizedCanvas.toDataURL())

        const grayscaleImageData = _grayscale(pixelizedImageData)
        const grayscaleCanvas = document.createElement("CANVAS");
        grayscaleCanvas.width = grayscaleImageData.width
        grayscaleCanvas.height = grayscaleImageData.height
        const ctx3 = grayscaleCanvas.getContext("2d")
        ctx3.putImageData(grayscaleImageData, 0, 0);
        setGrayscaleImage(grayscaleCanvas.toDataURL())
        setIsPixelize(false)
        //   setResult(result)
    }

    const _onResize = () => {
        if (outputRef.current.hasChildNodes()) {
            outputRef.current.removeChild(outputRef.current.children[0])
        }
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        const image = window.photon.open_image(canvas, ctx)
        const iH = originalDim.height
        const iW = originalDim.width
        let newCanvas = window.photon.resize_img_browser(image, parseInt(iW / pixelSize), parseInt(iH / pixelSize), resizeType)
        // console.log(resizedImgRef.current)
        // resizedImgRef.current.src = newCanvas.toDataURL()
        const newCtx = newCanvas.getContext('2d')
        const imageData = newCtx.getImageData(0, 0, newCanvas.width, newCanvas.height)
        const data = imageData.data;
        // console.log(data)
        for (let i = 0; i < 24; i += 4) {
            data[i] = data[24]
            data[i + 1] = data[25]
            data[i + 2] = data[26]
            data[i + 3] = data[27]
        }
        //    const pos = (30 * imageData.width + 14) * 4
        //     console.log('wrong pixel:', pos, data[pos], data[pos + 1], data[pos + 2]) 

        // console.log(imageData.data)
        newCtx.putImageData(imageData, 0, 0);
        outputRef.current.appendChild(newCanvas)
        setResizedImage(newCanvas.toDataURL())

        // const grayscaleCanvas = document.createElement("CANVAS");
        // grayscaleCanvas.width = parseInt(iW / pixelSize)
        // grayscaleCanvas.height = parseInt(iH / pixelSize)
        // const ctx2 = grayscaleCanvas.getContext("2d")
        // ctx2.putImageData(_grayscale(newCanvas.getContext('2d').getImageData(0, 0, parseInt(iW / pixelSize), parseInt(iH / pixelSize))), 0, 0);
        // setGrayscaleImage(grayscaleCanvas.toDataURL())
    }

    const _changeResizeType = (value) => {
        setResizeType(value)
    }

    const _grayscale = (imageData) => {
        const newImageData = new ImageData(imageData.width, imageData.height);
        newImageData.data.set(imageData.data);
        const data = newImageData.data;
        for (var i = 0; i < data.length; i += 4) {
            const avg = 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2]
            data[i] = avg; // red
            data[i + 1] = avg; // green
            data[i + 2] = avg; // blue
        }
        return newImageData
    }

    const _mapColor = (imageData, clusteredColors, colorPalette) => {
        // console.log(colorPalette)
        const newImageData = new ImageData(imageData.width, imageData.height);
        newImageData.data.set(imageData.data);
        // console.log(newImageData)

        const data = newImageData.data
        // console.log(data)
        for (var i = 0; i < data.length; i += 4) {
            const r = data[i]
            const g = data[i + 1]
            const b = data[i + 2]
            if (r === 255 && g === 255 && b === 255) {
                data[i] = 255
                data[i + 1] = 255
                data[i + 2] = 255
            }
            else if (data[i + 3] === 0) {
                data[i] = 255
                data[i + 1] = 255
                data[i + 2] = 255
            }
            else {
                const category = clusteredColors.find(x => x.r === r && x.g === g && x.b === b)?.category
                // if (i === (31 * imageData.width + 14) * 4)
                //     console.log(category)
                const hex = colorPalette[category]
                // console.log(r, g, b, category)
                const rgb = hexToRgb(hex)
                data[i] = rgb[0]
                data[i + 1] = rgb[1]
                data[i + 2] = rgb[2]
                // data[i + 3] = 255
            }

            // console.log(data[i+3])
        }
        return newImageData
    }

    const _export = () => {
        const data = exportImageData.data
        const pixels = []
        const colors = {
            //    "0": "#ffffff",
        }
        const grayscaleColors = {
            // "0": "#ffffff",
        }
        const rows = exportImageData.height
        const cols = exportImageData.width
        // console.log(rows, cols)

        for (let row = 0; row < rows; row++) {
            pixels.push([])
            for (let col = 0; col < cols; col++) {
                const r = data[row * cols * 4 + col * 4];
                const g = data[row * cols * 4 + col * 4 + 1];
                const b = data[row * cols * 4 + col * 4 + 2];
                // const r = data[i]
                // const g = data[i + 1]
                // const b = data[i + 2]

                // set transparent background to white (category 0)
                if (data[row * cols * 4 + col * 4 + 3] === 0)
                    pixels[row][col] = 0
                // set #ffffff to category 0
                else if (r === 255 && g === 255 && b === 255) {
                    pixels[row][col] = 0
                }
                else {
                    const category = result.clusteredColors.find(x => x.r == r && x.g == g && x.b == b)?.category
                    // const hex = rgbToHex([r, g, b])
                    // const category = result.colorPalette.indexOf(hex) + 1
                    // console.log(category+1)
                    pixels[row][col] = category + 1
                }

            }
        }

        result.colorPalette.forEach((hex, index) => {
            colors[index + 1] = hex
            const [r, g, b] = hexToRgb(hex)
            const avg = parseInt(0.299 * r + 0.587 * g + 0.114 * b)
            grayscaleColors[index + 1] = rgbToHex([avg, avg, avg])
        })
        // console.log(colors)
        // console.log(grayscaleColors)

        const exportData = {
            matrix: pixels,
            colors,
            grayscaleColors
        }

        const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "image.json");
    }

    return (
        <Layout className="container">
            <Spin tip="Pixelizing..." size='large' spinning={isPixelize} indicator={<ChromeOutlined spin />}>
                <Layout.Content style={{ overflow: 'hidden scroll', height: window.innerHeight - 70 }}>
                    <Row gutter={10}>
                        <Col span={6}>
                            <div className='image-title'>
                                <span>Original Image {originalDim.width}x{originalDim.height}</span>
                            </div>
                            <img ref={imageRef} width='100%' src={`data:${mimeType};base64,${base64}`} />
                            <canvas ref={canvasRef} style={{ display: 'none' }} />
                        </Col>
                        <Col span={6}>
                            <div className='image-title'>
                                <span>Resized Image {parseInt(originalDim.width / pixelSize)}x{parseInt(originalDim.height / pixelSize)}</span>
                            </div>
                            <div style={{ display: 'none' }} ref={outputRef}></div>
                            <Image src={resizedImage} width={'100%'} />
                        </Col>
                        {/* </Row>
                    <Row gutter={10}> */}
                        <Col span={6}>
                            <div className='image-title'>
                                <span>Product Image {parseInt(originalDim.width / pixelSize)}x{parseInt(originalDim.height / pixelSize)}</span>
                            </div>
                            <Image src={grayscaleImage} width={'100%'} />
                        </Col>
                        <Col span={6}>
                            <div className='image-title'>
                                <span>Product Image After Coloring {parseInt(originalDim.width / pixelSize)}x{parseInt(originalDim.height / pixelSize)}</span>
                            </div>
                            <Image src={pixelizedImage} width={'100%'} />
                        </Col>
                    </Row>
                    <Row>
                        <ul>
                            {result?.colorPalette.map((value, index) => (
                                <li className='color-box' style={{ background: value }} key={index}>{index}</li>
                            ))}
                        </ul>
                    </Row>
                </Layout.Content>
                <Layout.Footer>
                    <Space wrap>
                        <input type='file' accept=".png, .jpg, .jpeg" onChange={(event) => {
                            event.preventDefault()
                            const file = event.target.files[0]
                            setMimeType(file.type)
                            const reader = new window.FileReader()
                            reader.readAsArrayBuffer(file)

                            reader.onloadend = () => {
                                setBase64(Buffer(reader.result).toString('base64'))
                            }
                        }} />
                        <b>Pixel Size:</b>
                        <InputNumber
                            style={{ width: 100 }}
                            value={pixelSize} min="1" max="20" step="1"
                            onChange={setPixelSize}
                        />
                        <b>Resize Type:</b>
                        <Select defaultValue={1} style={{ width: 120 }} onChange={_changeResizeType}>
                            <Option value={1}>Normal</Option>
                            <Option value={2}>CatmullRom</Option>
                            <Option value={3}>Triangle</Option>
                            <Option value={4}>Gaussian</Option>
                            <Option value={5}>Lanczos3</Option>
                        </Select>
                        <b>Number Colors:</b>
                        <InputNumber
                            style={{ width: 100 }}
                            value={numberOfColors} min="1" max="100" step="1"
                            onChange={setNumberOfColors}
                        />
                        {/* <Button type='primary' onClick={_onResize}>Resize</Button> */}
                        <Button type='primary' onClick={() => {
                            setIsPixelize(true)
                            setTimeout(() => { _onClickPixelize() }, 100)
                        }}>Pixelize</Button>
                        <Button onClick={_export}>Export JSON</Button>
                    </Space>
                </Layout.Footer>
            </Spin>
        </Layout>
    )
}

export default Pixelizer;