import React from 'react';
import './App.less';
import ColoringApp from './components/coloring-app';
// import RockIdentifier from './components/rock-identifier';

const App = () => (
  <>
    {/* <RockIdentifier /> */}
    <ColoringApp />
  </>
);

export default App;