function rgbToHsl(r, g, b) {
	r /= 255
	g /= 255
	b /= 255;
	var max = Math.max(r, g, b),
		min = Math.min(r, g, b);
	var h, s, l = (max + min) / 2;

	if (max == min) {
		h = s = 0; // achromatic
	} else {
		var d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h *= 60;
	}

	return [h, s * 100, l * 100];
}

function hslToRgb(h, s, l) {
	h = h / 360;
	s = s / 100;
	l = l / 100;
	var r, g, b;

	if (s == 0) {
		r = g = b = l; // achromatic
	} else {
		var hue2rgb = function hue2rgb(p, q, t) {
			if (t < 0) t += 1;
			if (t > 1) t -= 1;
			if (t < 1 / 6) return p + (q - p) * 6 * t;
			if (t < 1 / 2) return q;
			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
			return p;
		}

		var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		var p = 2 * l - q;
		r = hue2rgb(p, q, h + 1 / 3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1 / 3);
	}
	return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function rgbToHex(rgbArr) {
	var color = '#';
	var tmp;
	for (var i = 0; i < rgbArr.length; i++) {
		tmp = rgbArr[i].toString(16);
		color += tmp.length < 2 ? ('0' + tmp) : tmp;
	}
	return color;
}

function hexToRgb(hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? [
		parseInt(result[1], 16),
		parseInt(result[2], 16),
		parseInt(result[3], 16)
	] : null;
}

function parseRgbString(rgb) {
	const s = rgb.split("(")[1].split(")")[0];
	return s.split(',').map(x => parseInt(x.trim()))
}

function rgbToGray(r, g, b) {
	return 0.299*r + 0.587*g + 0.114*b
}

function extractSvgColorStyle(svgString) {
	// console.log(svgString);
	const styleRegex = /(\<style type=\"text\/css\"\>)([\s\S]*)(\<\/style\>)/g
	const cssAttrs = {}

	if (svgString.match(styleRegex)) {
		const styleString = svgString.match(styleRegex)[0]
		styleString.replace(styleRegex, `\$2`).split('\n').filter(x => x.trim()).forEach(
			attr => {
				// console.log("d",attr, 'd')
				// console.log(attr)
				const key = attr.split('{')[0].trim()
				// console.log(key)
				const value = attr.split('{')[1].replace('}', '').trim().replace('fill:', '').replace(';', '')
				cssAttrs[key] = value
			}
		)
	}

	// console.log(cssAttrs)

	return cssAttrs
}

export {
	rgbToHsl,
	hslToRgb,
	rgbToHex,
	hexToRgb,
	rgbToGray,
	parseRgbString,
	extractSvgColorStyle
}