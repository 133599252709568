// import brain from './brain'
import { rgbToHsl, hslToRgb, rgbToHex, hexToRgb } from './utils.js';
// import { network1, network2 } from './trainData.js';

// console.log(brain)
// const net = new window.brain.NeuralNetwork();
// const net2 = new window.brain.NeuralNetwork();
// net.fromJSON(network1);
// net2.fromJSON(network2);

function chooseSeedColors(colors, num) {
    let init_seed = [];

    // console.log("num", num);
    // console.log("color", colors);
    let len = colors.length;
    let l;
    for (let i = 0; i < len; i++) {
        l = init_seed.length;
        let color = colors[i];
        if (!i) {
            color.category = 0;
            init_seed.push({
                h: color.h,
                s: color.s,
                l: color.l,
                category: color.category,
                fre: color.fre
            });
            continue;
        }
        let j = 0;
        for (; j < l; j++) {
            let h_diff = Math.abs(init_seed[j].h - color.h);
            let s_diff = Math.abs(init_seed[j].s - color.s);
            let l_diff = Math.abs(init_seed[j].l - color.l);
            if (h_diff + s_diff + l_diff < 0) {
                break;
            }
        }
        if (j === l) {
            color.category = init_seed.length;
            init_seed.push({
                h: color.h,
                s: color.s,
                l: color.l,
                category: color.category,
                fre: color.fre
            });
        }
        if (init_seed.length >= num) {
            break;
        }
    }
    // console.log(init_seed);
    return init_seed;
}

function clusteringColors(K, listColors) {
    // console.log(K, listColors)
    let colors = listColors
    if (typeof listColors[0] === 'string' && listColors[0].startsWith('#')) { // convert hex to rgb
        colors = listColors.map(hex => hexToRgb(hex))
    }
    // console.log(colors)

    let start = +new Date();
    let processInfo = {
        colors: 0,
        censusTime: 0,
        kmeansIteration: 0,
        kmeansTime: 0,
        top5Count: 0
    };
    const keys = []
    let colors_info = []
    colors.forEach(color => {
        const r = color[0]
        const g = color[1]
        const b = color[2]
        const hsl = rgbToHsl(r, g, b)
        const key = `${r}:${g}:${b}`
        // console.log(color, hsl)
        let index = keys.indexOf(key);
        if (index < 0) {
            keys.push(key);
            colors_info.push({
                key,
                fre: 1,
                r,
                g,
                b,
                h: hsl[0],
                s: hsl[1],
                l: hsl[2],
                category: -1
            });
        } else {
            // if (r != colors_info[index].r || g != colors_info[index].g || b != colors_info[index].b) {
            //     console.log(`${r}:${g}:${b}`)
            // }
            colors_info[index].fre++;
        }
    })

    console.log('total:', colors_info.reduce((acc, cur) => acc + cur.fre, 0))
    // console.log(colors_info)
    // console.log("pixel_count: ",pixel_count)
    processInfo.censusTime = +new Date() - start;
    processInfo.colors = colors_info.length;
    console.log("time for process all pixel: ", processInfo.censusTime)

    start = +new Date();
    // sort and filter rgb_census
    colors_info.sort(function (pre, next) {
        return next.fre - pre.fre
    })
    let len = colors_info.length
    console.log("before filter: ", len)
    // colors_info = colors_info.filter((color) => {
    //     // isolated color
    //     let flag = color.fre < 5 && len > 400
    //     return !flag
    // });
    console.log("after filter: ", colors_info.length)

    // // k-mean clustering
    let init_seed_1 = chooseSeedColors(colors_info, K);
    let cluster_res = kMC(colors_info, init_seed_1, 100);
    let cluster_res_1 = cluster_res[0];
    cluster_res_1 = cluster_res_1.map((color) => {
        return rgbToHex(hslToRgb(color.h, color.s, color.l));
    });

    let r_count = 0, g_count = 0, b_count = 0, f_count = 0;
    len = colors_info.length;
    while (len--) {
        r_count += colors_info[len].r * colors_info[len].fre;
        g_count += colors_info[len].g * colors_info[len].fre;
        b_count += colors_info[len].b * colors_info[len].fre;
        f_count += colors_info[len].fre;
    }

    // let average_color = rgbToHsl(Math.floor(r_count / f_count), Math.floor(g_count / f_count), Math.floor(b_count / f_count));
    // average_color = {
    //     h: average_color[0],
    //     s: average_color[1],
    //     l: average_color[2],
    // };
    // let main_color_a = "rgba(" + colors_info[0].r + "," + colors_info[0].g + "," + colors_info[0].b + ",0.62)";

    processInfo.kmeansTime = +new Date() - start;
    processInfo.kmeansIteration = cluster_res[1];
    console.log("time for K-means: ", processInfo.kmeansTime);
    // let info = imageScore(colors_info);
    // processInfo.top5Count = info.top5Count * 100;
    // console.log(colors_info, cluster_res_1)
    return {
        clusteredColors: colors_info,
        colorPalette: cluster_res_1
    }

}

function kMC(colors, seeds, max_step) {
    seeds.sort(function (pre, next) {
        let pre_rgb = hslToRgb(pre.h, pre.s, pre.l);
        pre_rgb = pre_rgb[0] + pre_rgb[1] + pre_rgb[2];
        // let next_h = next.h;
        // next_h = next_h < 30 ? (next_h+330) : next_h;
        let next_rgb = hslToRgb(next.h, next.s, next.l);
        next_rgb = next_rgb[0] + next_rgb[1] + next_rgb[2];
        return next_rgb - pre_rgb;
    });
    // console.log('seed', seeds)

    let iteration_count = 0;

    while (iteration_count++ < max_step) {
        // filter seeds
        seeds = seeds.filter((seed) => {
            return seed;
        });

        // divide colors into different categories with duff's device
        let len = colors.length;
        let count = (len / 8) ^ 0;
        let start = len % 8;
        while (start--) {
            classifyColor(colors[start], seeds);
        }
        while (count--) {
            classifyColor(colors[--len], seeds);
            classifyColor(colors[--len], seeds);
            classifyColor(colors[--len], seeds);
            classifyColor(colors[--len], seeds);
            classifyColor(colors[--len], seeds);
            classifyColor(colors[--len], seeds);
            classifyColor(colors[--len], seeds);
            classifyColor(colors[--len], seeds);
        }

        // compute center of category
        len = colors.length;
        let hsl_count = [];
        let category;
        while (len--) {
            category = colors[len].category;
            if (!hsl_count[category]) {
                hsl_count[category] = {};
                hsl_count[category].h = 0;
                hsl_count[category].s = 0;
                hsl_count[category].l = 0;
                hsl_count[category].fre_count = colors[len].fre;
            } else {
                hsl_count[category].fre_count += colors[len].fre;
            }
        }
        len = colors.length;
        while (len--) {
            category = colors[len].category;
            hsl_count[category].h += colors[len].h * colors[len].fre / hsl_count[category].fre_count;
            hsl_count[category].s += colors[len].s * colors[len].fre / hsl_count[category].fre_count;
            hsl_count[category].l += colors[len].l * colors[len].fre / hsl_count[category].fre_count;
        }
        let flag = hsl_count.every((ele, index) => {
            return Math.abs(ele.h - seeds[index].h) < 0.5 && Math.abs(ele.s - seeds[index].s) < 0.5 && Math.abs(ele.l - seeds[index].l) < 0.5;
        });
        seeds = hsl_count.map((ele, index) => {
            return {
                h: ele.h,
                s: ele.s,
                l: ele.l,
                category: index,
                fre: ele.fre_count
            };
        });
        if (flag) {
            break;
        }
    }
    console.log("KMC iteration " + iteration_count);
    return [seeds, iteration_count];
}

function classifyColor(color, classes) {
    let len = classes.length;
    let min = 10000;
    let min_index;
    while (len--) {
        let distance = Math.abs(classes[len].h - color.h) + Math.abs(classes[len].s - color.s) + Math.abs(classes[len].l - color.l);
        if (distance < min) {
            min = distance;
            min_index = len;
        }
    }
    color.category = min_index;
}

// function imageScore(colorInfo) {
//     let info = {
//         colorCount: (Math.log10(colorInfo.length)),
//         average: 0,
//         variance: 0,
//         top50Count: 0,
//         top50Average: 0,
//         top50Variance: 0,
//         top20Count: 0,
//         top20Average: 0,
//         top20Variance: 0,
//         top10Count: 0,
//         top10Average: 0,
//         top10Variance: 0,
//         top5Count: 0,
//         top5Average: 0,
//         top5Variance: 0
//     };
//     let average = 0;
//     let variance = 0;
//     let count = 0;
//     let top50_count = 0;
//     let top50_average = 0;
//     let top50_variance = 0;
//     let top20_count = 0;
//     let top20_average = 0;
//     let top20_variance = 0;
//     let top10_count = 0;
//     let top10_average = 0;
//     let top10_variance = 0;
//     let top5_count = 0;
//     let top5_average = 0;
//     let top5_variance = 0;
//     let len = colorInfo.length;
//     let color;
//     while (len--) {
//         color = colorInfo[len];
//         count += color.fre;
//         if (len < 50) {
//             top50_count += color.fre;
//             if (len < 20) {
//                 top20_count += color.fre;
//                 if (len < 10) {
//                     top10_count += color.fre;
//                     if (len < 5) {
//                         top5_count += color.fre;
//                     }
//                 }
//             }
//         }
//     }
//     len = colorInfo.length;
//     info.average = (Math.log10(count / len));
//     info.top50Average = (Math.log10(top50_count / 50));
//     info.top50Count = top50_count / count;
//     info.top20Average = (Math.log10(top20_count / 20));
//     info.top20Count = top20_count / count;
//     info.top10Average = (Math.log10(top10_count / 10));
//     info.top10Count = top10_count / count;
//     info.top5Average = (Math.log10(top5_count / 5));
//     info.top5Count = top5_count / count;
//     average = count / len;
//     top50_average = top50_count / 50;
//     top20_average = top20_count / 50;
//     top10_average = top10_count / 50;
//     top5_average = top5_count / 50;
//     while (len--) {
//         color = colorInfo[len];
//         variance += (color.fre - average) * (color.fre - average);
//         if (len < 50) {
//             top50_variance += (color.fre - top50_average) * (color.fre - top50_average);
//             if (len < 20) {
//                 top20_variance += (color.fre - top20_average) * (color.fre - top20_average);
//                 if (len < 10) {
//                     top10_variance += (color.fre - top10_average) * (color.fre - top10_average);
//                     if (len < 5) {
//                         top5_variance += (color.fre - top5_average) * (color.fre - top5_average);
//                     }
//                 }
//             }
//         }
//     }
//     len = colorInfo.length;
//     variance = Math.sqrt(variance / len);
//     top50_variance = Math.sqrt(top50_variance / 50);
//     top20_variance = Math.sqrt(top20_variance / 50);
//     top10_variance = Math.sqrt(top10_variance / 50);
//     top5_variance = Math.sqrt(top5_variance / 50);
//     info.variance = Math.log10(variance);
//     info.top50Variance = Math.log10(top50_variance);
//     info.top20Variance = Math.log10(top20_variance);
//     info.top10Variance = Math.log10(top10_variance);
//     info.top5Variance = Math.log10(top5_variance);
//     // normalization
//     let max = -1;
//     for (let key in info) {
//         if (info[key] > max) {
//             max = info[key];
//         }
//     }
//     for (let key in info) {
//         if (info[key] > 1) {
//             info[key] = info[key] / max;
//         }
//     }
//     // let t = 0.7 + 0.1*Math.random();
//     // let s = (1-t)*Math.random();
//     // let f = 1-t-s;
//     // this.infos.push({
//     //   input: info,
//     //   output: {
//     //     first: 0,
//     //     second: s,
//     //     third: t,
//     //     fourth: f
//     //   }
//     // });
//     // if(this.infos.length===20){
//     //   console.log(JSON.stringify(this.infos))
//     // }
//     let t = net.run(info);
//     let t2 = net2.run(info);
//     let res_count = t.first + t.second + t.third + t.fourth;
//     let score = 100 * t.first / res_count + 85 * t.second / res_count + 75 * t.third / res_count + 65 * t.fourth / res_count;
//     let res_count2 = t2.first + t2.second + t2.third + t2.fourth;
//     let score2 = 100 * t2.first / res_count2 + 85 * t2.second / res_count2 + 75 * t2.third / res_count2 + 65 * t2.fourth / res_count2;
//     let score_final = score * 0.2 + score2 * 0.8;
//     console.log("score: ", score_final)
//     // setTimeout(()=>{
//     //   this.setState({score:score_final});
//     // },650);
//     // setScore(score_final);
//     return info;
// }

export { clusteringColors }